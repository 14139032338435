import { OCPP_API } from "./common/http-common";

const tenant = process.env.VUE_APP_TENANT;

export function getAllVat() {
  return OCPP_API.get(`/${tenant}/market/v1.0/vat`);
}

export function addVat(vat) {
  return OCPP_API.post(`/${tenant}/market/v1.0/vat`, vat);
}

export function updateVat(id, vat) {
  return OCPP_API.put(`/${tenant}/market/v1.0/vat/${id}`, vat);
}

export function deleteVat(id) {
  return OCPP_API.delete(`/${tenant}/market/v1.0/vat/${id}`);
}
