<template>
  <div class="mt-4 mb-4">
    <div
      class="mt-5 card vatAreaCard"
      v-for="[areaKey, area] in Object.entries(constants.AREAS)"
      :key="areaKey"
    >
      <div class="m-3">
        <h3>{{ area }}</h3>
        <div v-for="vat in getVatForArea(areaKey)" :key="vat.id">
          <b-row align-v="end">
            <b-col cols="3">
              <label>Start date</label>
              <b-form-datepicker
                :disabled="!canBeModified(vat.startDate)"
                :min="getEarliestDateForModification()"
                v-model="vat.startDate"
              ></b-form-datepicker>
            </b-col>

            <b-col cols="3">
              <label>End date</label>
              <b-form-datepicker
                disabled
                v-model="vat.endDate"
              ></b-form-datepicker>
            </b-col>

            <b-col cols="1">
              <label>Value (%)</label>
              <b-form-input
                :state="valueValidationState(vat.value)"
                :disabled="!canBeModified(vat.startDate)"
                v-model="vat.value"
              ></b-form-input>
            </b-col>

            <b-col cols="2" class="mb-1" v-if="canBeModified(vat.startDate)">
              <div class="">
                <b-button
                  class="actionBtn updateVatButton"
                  @click="updateVat(vat)"
                >
                  <font-awesome-icon icon="save" />
                </b-button>

                <b-button
                  class="actionBtn ml-4 deleteVatButton"
                  @click="showDeleteModal(vat)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="mt-4">
          <h4>Add new VAT</h4>
          <b-row align-v="end">
            <b-col cols="3">
              <label>Start date</label>
              <b-form-datepicker
                v-model="newVatEntries[areaKey].startDate"
                :min="getEarliestDateForModification()"
              ></b-form-datepicker>
            </b-col>

            <b-col cols="1">
              <label>Value (%)</label>
              <b-form-input
                :state="valueValidationState(newVatEntries[areaKey].value)"
                v-model="newVatEntries[areaKey].value"
              ></b-form-input>
            </b-col>

            <b-col cols="1" class="mb-1">
              <div class="">
                <b-button
                  class="actionBtn btn--alt addVatButton"
                  @click="addVat(areaKey)"
                >
                  <div>Add</div>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <b-modal
      size="lg"
      ref="deleteModal"
      id="deleteModal"
      title="Delete VAT"
      hide-footer
    >
      <b-card>
        <b-card-text v-if="this.vatSelectedForDelete != null">
          Are you sure wish to delete VAT for
          {{ this.constants.AREAS[this.vatSelectedForDelete.area] }} from
          {{ this.vatSelectedForDelete.startDate }} to
          {{ this.vatSelectedForDelete.endDate }} with value
          {{ this.vatSelectedForDelete.value }}%?
        </b-card-text>

        <b-card-text class="mt-4">
          Deleting a VAT will automatically adjust the end date of the preceding
          VAT
        </b-card-text>

        <div class="mt-4">
          <b-button
            class="btn--alert"
            @click="deleteVat(vatSelectedForDelete.id)"
          >
            Delete
          </b-button>

          <b-button class="ml-3" @click="closeDeleteModal"> Cancel </b-button>
        </div>
      </b-card>
    </b-modal>

    <b-alert
      class="fixed-top"
      :show="alertMessageCountDown"
      dismissible
      :variant="alertMessageVariant"
      @dismissed="alertMessageCountDown = 0"
    >
      <h4>{{ alertMessageTitle }}</h4>
      <p>{{ alertMessage }}</p>
    </b-alert>
  </div>
</template>

<script>
import { addVat, deleteVat, getAllVat, updateVat } from "@/api/MarketVatApi.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { supportedCountries } from "@/utils/countryUtils";
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormDatepicker,
  BFormInput,
  BModal,
  BRow,
} from "bootstrap-vue";

library.add(faSave, faTrash);
export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-datepicker": BFormDatepicker,
    "b-form-input": BFormInput,
    "b-button": BButton,
    "b-modal": BModal,
    "b-card": BCard,
    "b-card-text": BCardText,
  },
  name: "vatManagement",
  created() {
    this.constants = {
      MESSAGE_COUNTDOWN_SECONDS: 5,
      AREAS: supportedCountries,
      NEW_VAT_FIELD_INITIAL_STATE: {
        EE: {
          value: 0,
        },
        LV: {
          value: 0,
        },
        LT: {
          value: 0,
        },
      },
    };
  },
  data() {
    return {
      vats: [],
      newVatEntries: {
        EE: {
          value: 0,
        },
        LV: {
          value: 0,
        },
        LT: {
          value: 0,
        },
      },
      vatSelectedForDelete: null,
      alertMessageCountDown: 0,
      alertMessageTitle: "",
      alertMessage: "",
      alertMessageVariant: "danger",
    };
  },
  methods: {
    getEarliestDateForModification() {
      const minValidDate = new Date();
      minValidDate.setDate(minValidDate.getDate() + 3);
      minValidDate.setHours(0, 0, 0, 0);
      return minValidDate;
    },
    canBeModified(startDate) {
      const start = new Date(Date.parse(startDate));
      return this.getEarliestDateForModification() <= start;
    },
    valueValidationState(value) {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue >= 0 && numberValue <= 99.99
        ? null
        : false;
    },
    vatModificationValid(startDate, value) {
      return (
        this.canBeModified(startDate) &&
        this.valueValidationState(value) == null
      );
    },
    getVats() {
      return new Promise((resolve, reject) => {
        const vm = this;
        getAllVat()
          .then((res) => {
            vm.vats = res.data;
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    getVatForArea(area) {
      return this.vats.filter((v) => v.area === area);
    },
    addVat(areaKey) {
      const vm = this;
      const vatToAdd = {
        area: areaKey,
        ...this.newVatEntries[areaKey],
      };
      if (!this.vatModificationValid(vatToAdd.startDate, vatToAdd.value)) {
        return;
      }
      addVat(vatToAdd)
        .then(() => vm.handleAlert(vm, "Add", "Added VAT", "success"))
        .then(() => vm.clearNewVatFields())
        .then(() => vm.getVats())
        .catch((err) => this.handleError(vm, "VAT add", err));
    },
    clearNewVatFields() {
      this.newVatEntries = this.constants.NEW_VAT_FIELD_INITIAL_STATE;
    },
    updateVat(vat) {
      const vm = this;
      const vatUpdate = {
        area: vat.area,
        startDate: vat.startDate,
        value: vat.value,
      };
      if (!this.vatModificationValid(vatUpdate.startDate, vatUpdate.value)) {
        return;
      }
      updateVat(vat.id, vatUpdate)
        .then(() =>
          vm.handleAlert(vm, "Update", "Updated VAT values", "success")
        )
        .then(() => vm.getVats())
        .catch((err) => this.handleError(vm, "VAT update", err));
    },
    showDeleteModal(vat) {
      this.vatSelectedForDelete = vat;
      this.$refs["deleteModal"].show();
    },
    closeDeleteModal() {
      this.$refs["deleteModal"].hide();
      this.vatSelectedForDelete = null;
    },
    deleteVat(id) {
      const vm = this;
      deleteVat(id)
        .then(() => vm.handleAlert(vm, "Delete", "Deleted VAT", "success"))
        .then(() => vm.getVats())
        .then(() => vm.closeDeleteModal())
        .catch((err) => this.handleError(vm, "VAT delete", err));
    },
    handleError(vm, title, err) {
      console.error(err);
      const errorResponse = err.response.data;
      const errorMessage = errorResponse.violations ?? errorResponse.detail;
      vm.handleAlert(vm, title, JSON.stringify(errorMessage), "danger");
    },
    handleAlert(vm, title, message, variant) {
      vm.alertMessageTitle = title;
      vm.alertMessage = message;
      vm.alertMessageVariant = variant;

      vm.alertMessageCountDown = vm.constants.MESSAGE_COUNTDOWN_SECONDS;
    },
  },
  beforeMount() {
    this.getVats();
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
